import debounce from 'lodash.debounce';
import Popup from '../popup/_popup';
export default class CardPartner {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;

        this.root = el;
        this.id = this.root.getAttribute('data-card-partner');
        this.popupEl = null;

        return true;
    }

    init() {
        this.setCard();
    }

    setCard() {
        this.onPopup = debounce(this.loadPopup.bind(this), 1000);
        this.root.addEventListener('click', this.onPopup);
    }

    async loadPopup() {
        const formData = new FormData();
        if (this.id) formData.append('id', this.id);

        const request = await fetch(`${home_url}/wp-json/partners/v1/popup`, {
            method: 'POST',
            body: formData,
        });

        const res = await request.json();
        const { html } = res;
        document.body.insertAdjacentHTML('beforeend', html);

        this.popupEl = document.querySelector('[data-popup]');
        new Popup(this.popupEl);
    }
}
