import CardPartner from '../components/cards/_card-partner';
class Partners {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;

        this.root = el;
        this.localizationButton = this.root.querySelectorAll(
            '[data-partners-localization]'
        );
        this.branchButton = this.root.querySelectorAll(
            '[data-partners-branch]'
        );
        this.lodaerEl = this.root.querySelector('[data-partners-loader]');
        this.gridEl = this.root.querySelector('[data-partners-grid]');
        this.moreEl = this.root.querySelector('[data-partners-more]');

        this.settings = JSON.parse(this.root.getAttribute('data-settings'));

        this.html = this.gridEl.innerHTML;
        this.currPage = 1;
        this.currLocalization = null;
        this.currBranch = null;
        this.maxPages = parseInt(this.settings.maxPages);

        if (this.settings.localization)
            this.currLocalization = parseInt(this.settings.localization);
        if (this.settings.branch)
            this.currBranch = parseInt(this.settings.branch);

        return true;
    }

    init() {
        this.setLocalizationButtons();
        this.setBranchButtons();
        this.setMoreButton();
        this.setCards();
        this.checkMoreButton();
    }

    setLocalizationButtons() {
        if (!this.localizationButton) return;

        this.onLocalization = this.onLocalizationClick.bind(this);
        this.localizationButton.forEach(item =>
            item.addEventListener('click', this.onLocalization)
        );
    }

    setBranchButtons() {
        if (!this.branchButton) return;

        this.onBranch = this.onBranchClick.bind(this);
        this.branchButton.forEach(item =>
            item.addEventListener('click', this.onBranch)
        );
    }

    setMoreButton() {
        if (!this.moreEl) return;

        this.onMore = this.onMoreClick.bind(this);
        this.moreEl.addEventListener('click', this.onMore);
    }

    setCards() {
        this.cardEl = this.root.querySelectorAll('[data-card-partner]');
        if (!this.cardEl) return;

        this.cardEl.forEach(item => {
            new CardPartner(item);
        });
    }

    async onLocalizationClick(event) {
        const { currentTarget } = event;
        const id = parseInt(
            currentTarget.getAttribute('data-partners-localization')
        );

        this.currLocalization = id;
        this.currPage = 1;
        this.html = '';

        this.resetLocalizations();
        this.setActiveItem(currentTarget);

        await this.loadPartners();
    }

    async onBranchClick(event) {
        const { currentTarget } = event;
        const id = parseInt(currentTarget.getAttribute('data-partners-branch'));

        this.currBranch = id;
        this.currPage = 1;
        this.html = '';

        this.resetBranches();
        this.setActiveItem(currentTarget);

        await this.loadPartners();
    }

    async onMoreClick() {
        if (this.currPage < this.maxPages) {
            this.currPage += 1;
            await this.loadPartners();
        }
    }

    async loadPartners() {
        const formData = new FormData();
        if (this.currPage) formData.append('page', this.currPage);
        if (this.currLocalization)
            formData.append('localization', this.currLocalization);
        if (this.currBranch) formData.append('branch', this.currBranch);

        if (this.lodaerEl) this.lodaerEl.classList.remove('-hide');

        const request = await fetch(`${home_url}/wp-json/partners/v1/list`, {
            method: 'POST',
            body: formData,
        });

        const res = await request.json();
        const { html, max_pages } = res;

        this.html += html;
        this.gridEl.innerHTML = this.html;
        this.maxPages = parseInt(max_pages);

        this.checkMoreButton();
        this.setCards();

        if (this.lodaerEl) this.lodaerEl.classList.add('-hide');
    }

    resetBranches() {
        if (!this.branchButton) return;
        this.branchButton.forEach(item => item.classList.remove('-active'));
    }

    resetLocalizations() {
        if (!this.localizationButton) return;
        this.localizationButton.forEach(item =>
            item.classList.remove('-active')
        );
    }

    setActiveItem(item) {
        if (!item) return;
        item.classList.add('-active');
    }

    checkMoreButton() {
        if (this.currPage === this.maxPages || this.maxPages === 0) {
            this.root.classList.add('-no-results');
        } else {
            this.root.classList.remove('-no-results');
        }
    }
}

function initPartners() {
    const sections = document.querySelectorAll('[data-partners]');
    if (!sections) return;

    sections.forEach(item => new Partners(item));
}

if (
    document.readyState === 'complete' ||
    (document.readyState !== 'loading' && !document.documentElement.doScroll)
) {
    initPartners();
} else {
    document.addEventListener('DOMContentLoaded', initPartners);
}
